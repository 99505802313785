/**
* Choose colors at https://material.io/resources/color/#!/
*/
/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
*/

@import '~@angular/material/theming';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import 'https://fonts.googleapis.com/css?family=Material+Icons+Outlined';

@font-face {
  font-family: 'Diodrum';
  font-weight: 100;
  src: local('Diodrum'), url(assets/fonts/Diodrum-Extralight.ttf) format('truetype');
}
@font-face {
  font-family: 'Diodrum';
  font-weight: 300;
  src: local('Diodrum'), url(assets/fonts/Diodrum-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Diodrum';
  font-weight: 400;
  font-style: normal;
  src: local('Diodrum'), url(assets/fonts/Diodrum-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Diodrum';
  font-weight: 500;
  font-style: medium;
  src: local('Diodrum'), url(assets/fonts/Diodrum-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Diodrum';
  font-weight: 600;
  font-style: semibold;
  src: local('Diodrum'), url(assets/fonts/Diodrum-Semibold.ttf) format('truetype');
}
@font-face {
  font-family: 'Diodrum';
  font-weight: 700;
  font-style: bold;
  src: local('Diodrum'), url(assets/fonts/Diodrum-Bold.ttf) format('truetype');
}

@import '~@angular/material/theming';

// $fontConfig: mat-typography-config(
//   $font-family: 'Diodrum',
//   $headline: mat-typography-level(32px, 48px, 700),
//   $body-1: mat-typography-level(16px, 24px, 500)
// );

$fontConfig: (
  // mat-typography-level: font size, line height, font weight, font family, letter spacing
  display-4: mat-typography-level(112px, 112px, 700, 'Diodrum', -0.0134em),
  display-3: mat-typography-level(56px, 56px, 700, 'Diodrum', -0.0089em),
  display-2: mat-typography-level(45px, 48px, 700, 'Diodrum', 0.0000em),
  display-1: mat-typography-level(34px, 40px, 700, 'Diodrum', 0.0074em),
  headline: mat-typography-level(24px, 32px, 700, 'Diodrum', 0.0000em),
  title: mat-typography-level(20px, 32px, 600, 'Diodrum', 0.0075em),
  subheading-2: mat-typography-level(16px, 28px, 500, 'Diodrum', 0.0094em),
  subheading-1: mat-typography-level(15px, 24px, 500, 'Diodrum', 0.0067em),
  body-2: mat-typography-level(14px, 24px, 500, 'Diodrum', 0.0179em),
  body-1: mat-typography-level(14px, 20px, 400, 'Diodrum', 0.0179em),
  button: mat-typography-level(14px, 14px, 500, 'Diodrum', 0.0893em),
  caption: mat-typography-level(12px, 20px, 500, 'Diodrum', 0.0333em),
  input: mat-typography-level(inherit, 1.15, 500, 'Diodrum', 1.5px),
);

// Compute font config
@include mat-core($fontConfig);

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Icons Outlined', 'Material Icons';
  .mat-badge-content {
    font-family: 'Diodrum';
  }
}

// CSS Variable Mixin
@mixin theme-css-variables($theme, $background, $text) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  --text-color: #{$text};
  --background-color: #{$background};
  --primary-color: #{mat-color($primary)};
  --primary-lighter-color: #{mat-color($primary, lighter)};
  --primary-darker-color: #{mat-color($primary, darker)};
  --warn-color: #{mat-color($warn)};
  --warn-lighter-color: #{mat-color($warn, lighter)};
  --warn-darker-color: #{mat-color($warn, darker)};
  --accent-color: #{mat-color($accent)};
  --accent-lighter-color: #{mat-color($accent, lighter)};
  --accent-darker-color: #{mat-color($accent, darker)};
}

@import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../node_modules/ag-grid-community/dist/styles/ag-theme-material/sass/ag-theme-material-mixin";

@mixin ag-grid-angular-light() {
  .ag-theme-material {
    @include ag-theme-material((
        foreground-color: black,
        background-color: white,
        header-background-color: white,
        subheader-background-color: white,
        row-hover-color: null,
    ));
  }
}

@mixin ag-grid-angular-dark() {
  .ag-theme-material {
    @include ag-theme-material((
        foreground-color: white,
        background-color: #424242,
        header-foreground-color: white,
        header-background-color: #424242,
        subheader-background-color: #424242,
        row-hover-color: null,
    ));
  }
}

@mixin ngx-charts-light() {
  .ngx-chart-wrapper {
    .refline-label {
      font-size: 12px;
    }

    .refline-path {
      stroke-width: 2;
    }
  }
}

/**
* Same as ngx-charts demo https://stackoverflow.com/a/57675156
*/
@mixin ngx-charts-dark() {
  .ngx-chart-wrapper {
    /**
    * Backgrounds
    */
    $color-bg-darkest: #13141b;
    $color-bg-darker: #1b1e27;
    $color-bg-dark: #232837;
    $color-bg-med: #2f3646;
    $color-bg-light: #455066;
    $color-bg-lighter: #5b6882;

    /**
    * Text
    */
    $color-text-dark: #72809b;
    $color-text-med-dark: #919db5;
    $color-text-med: #A0AABE;
    $color-text-med-light: #d9dce1;
    $color-text-light: #f0f1f6;
    $color-text-lighter: #fff;

    // background: $color-bg-darker;

    .ngx-charts {

      text {
        fill: $color-text-light;
      }

      .tooltip-anchor {
        fill: rgb(255, 255, 255);
      }

      .gridline-path {
        stroke: $color-bg-med;
      }

      .refline-label {
        font-size: 12px;
      }

      .refline-path {
        stroke-width: 2;
        stroke: #fff;
      }

      .reference-area {
        fill: #fff;
      }

      .grid-panel {
        &.odd {
          rect {
            fill: rgba(255, 255, 255, 0.05);
          }
        }
      }

      .force-directed-graph {
        .edge {
          stroke: $color-bg-light;
        }
      }

      .number-card {
        p {
          color: $color-text-light;
        }
      }

      .gauge {
        .background-arc {
          path {
            fill: $color-bg-med;
          }
        }

        .gauge-tick {
          path {
            stroke: $color-text-med;
          }

          text {
            fill: $color-text-med;
          }
        }
      }

      .linear-gauge {
        .background-bar {
          path {
            fill: $color-bg-med;
          }
        }

        .units {
          fill: $color-text-dark;
        }
      }

      .timeline {
        .brush-background {
          fill: rgba(255, 255, 255, 0.05);
        }

        .brush {
          .selection {
            fill: rgba(255, 255, 255, 0.1);
            stroke: #aaa;
          }
        }
      }

      .polar-chart .polar-chart-background {
        fill: rgb(30, 34, 46);
      }

    }

    .chart-legend {
      .legend-labels {
        background: rgba(255, 255, 255, 0.05) !important;
      }

      .legend-item {
        &:hover {
          color: #fff;
        }
      }

      .legend-label {
        &:hover {
          color: #fff !important;
        }

        .active {
          .legend-label-text {
            color: #fff !important;
          }
        }
      }

      .scale-legend-label {
        color: $color-text-med;
      }
    }

    .advanced-pie-legend {
      color: $color-text-med;

      .legend-item {
        &:hover {
          color: #fff !important;
        }
      }
    }

    .number-card .number-card-label {
      font-size: 0.8em;
      color: $color-text-med;
    }
  }
}

@function mat-palette-generator($color, $color-lighter, $color-darker) {
  $default-dark-primary-text: rgba(#000000, 0.87);
  $default-light-primary-text: #ffffff;

  $mat-primary: (
    main: $color,
    lighter: $color-lighter,
    darker: $color-darker,
    200: $color, // For slide toggle,
    contrast : (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    )
  );
  $mat-theme-color: mat-palette($mat-primary, main, lighter, darker);

  @return $mat-theme-color;
}

@mixin light-theme-generator($theme) {
  @include ngx-charts-light();
  @include angular-material-theme($theme);
  @include theme-css-variables($theme, white, #000000de);
  @include ag-grid-angular-light();
}

@mixin dark-theme-generator($theme) {
  @include ngx-charts-dark();
  @include angular-material-theme($theme);
  @include theme-css-variables($theme, #424242, white);
  @include ag-grid-angular-dark();
}

/** Development Theme Config */

$dev-light-primary: mat-palette-generator(#0e59c1, #b7cdec, #083eab);
$dev-light-accent: mat-palette-generator(#43a047, #c7e3c8, #2c842f);
$dev-light-warn: mat-palette-generator(#ff0000, #ffb3b3, #c51e1e);
$dev-light-theme: mat-light-theme($dev-light-primary, $dev-light-accent, $dev-light-warn);
$dev-dark-primary: mat-palette-generator(#1878ff, #b7cdec, #204eaa);
$dev-dark-accent: mat-palette-generator(#5ead62, #c7e3c8, #429545);
$dev-dark-warn: mat-palette-generator(#df4949, #ffb3b3, #ce2c2c);
$dev-dark-theme: mat-dark-theme($dev-dark-primary, $dev-dark-accent, $dev-dark-warn);

body {
  --dev-theme-title:[development] Dhaara Live;
  --dev-theme-favicon: assets/dev.favicon.ico;
  --dev-theme-background: url("/assets/dev.background.png");
  --dev-theme-logo: url("/assets/dev.logo.name.png");
  --dev-theme-logo-square: url("/assets/dev.logo.png");
}

.dev-light-theme {
  @include light-theme-generator($dev-light-theme);
}

.dev-dark-theme {
  @include dark-theme-generator($dev-dark-theme);
}

/** Staging Theme Config */

$sta-light-primary: mat-palette-generator(#912fa5, #dec1e4, #741d8a);
$sta-light-accent: mat-palette-generator(#66bb6a, #d1ebd2, #49a44d);
$sta-light-warn: mat-palette-generator(#ff0000, #ffb3b3, #c51e1e);
$sta-light-theme: mat-light-theme($sta-light-primary, $sta-light-accent, $sta-light-warn);
$sta-dark-primary: mat-palette-generator(#d147ec, #dec1e4, #a327c2);
$sta-dark-accent: mat-palette-generator(#79e67e, #d1ebd2, #5dcc62);
$sta-dark-warn: mat-palette-generator(#df4949, #ffb3b3, #ce2c2c);
$sta-dark-theme: mat-dark-theme($sta-dark-primary, $sta-dark-accent, $sta-dark-warn);

body {
  --sta-theme-title:[staging] Dhaara Live;
  --sta-theme-favicon: assets/sta.favicon.ico;
  --sta-theme-background: url("/assets/sta.background.png");
  --sta-theme-logo: url("/assets/sta.logo.name.png");
  --sta-theme-logo-square: url("/assets/sta.logo.png");
}

.sta-light-theme {
  @include light-theme-generator($sta-light-theme);
}

.sta-dark-theme {
  @include dark-theme-generator($sta-dark-theme);
}

/** Kritsnam Theme Config */

$kri-light-primary: mat-palette-generator(#2b3c73, #bec6d4, #22305d);
$kri-light-accent: mat-palette-generator(#FDB740, #ffd285, #ffb028);
$kri-light-warn: mat-palette-generator(#ff0000, #ffb3b3, #c51e1e);
$kri-light-theme: mat-light-theme($kri-light-primary, $kri-light-accent, $kri-light-warn);
$kri-dark-primary: mat-palette-generator(#374c90, #bec6d4, #2a3b72);
$kri-dark-accent: mat-palette-generator(#FDB740, #ffd285, #ffb028);
$kri-dark-warn: mat-palette-generator(#df4949, #ffb3b3, #ce2c2c);
$kri-dark-theme: mat-dark-theme($kri-dark-primary, $kri-dark-accent, $kri-dark-warn);

body {
  --kri-theme-title:Dhaara Live;
  --kri-theme-favicon: assets/kri.favicon.ico;
  --kri-theme-background: url("/assets/kri.background.png");
  --kri-theme-logo: url("/assets/kri.logo.name.png");
  --kri-theme-logo-square: url("/assets/kri.logo.svg");
}

.kri-light-theme {
  @include light-theme-generator($kri-light-theme);
}

.kri-dark-theme {
  @include dark-theme-generator($kri-dark-theme);
}

// Default theme values overridden by theme.service.ts
body {
  --toolbar-title:Dhaara Live;
  --kri-theme-favicon: assets/kri.favicon.ico;
  --login-background-image: url("/assets/kri.background.png");
  --logo-image: url("/assets/kri.logo.name.png");
  --logo-square-image: url("/assets/kri.logo.svg");
}
